import React from "react"

import YAMLdata from "./about-front.yaml"

const AboutFront = () => {
  return (
    <section className="feature_area p_120">
      <div className="container">
        <div className="main_title">
          <h2>{YAMLdata.title}</h2>
          <p>{YAMLdata.content}</p>
        </div>
        <div className="feature_inner row">
          <div className="col-lg-4 col-md-6 d-flex align-content-stretch">
            <div className="feature_item">
              <i className="flaticon-consult"></i>
              <h4>
                Low MTTR Percentage (Mean Time To Resolve Production Issues)
              </h4>
              <p>
                Private LTE lab provides tools to recreate Roaming experiences
                and identify the root cause quickly.
              </p>
              <p className="mt-3">
                For equipment testing purposes, roaming scenarios can be
                dificult to test and troubleshoot. The new private-LTE set-up
                provides seamless transition from home to roaming environment,
                with the same level of services, without any need for additional
                interaction with other public mobile network operators.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-content-stretch">
            <div className="feature_item">
              <i className="flaticon-technology"></i>
              <h4>Better Visibility and Control </h4>
              <p>
                Among the significant connectivity improvements, the Enterprise
                now has better visibility and control over device testing,
                certification and trouble-shooting.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-content-stretch">
            <div className="feature_item">
              <i className="flaticon-suggestion"></i>
              <h4>Reduced Time to Market</h4>
              <p>
                Private-LTE enables Enterprises to timely deliver new features,
                technologies and upgrades by removing the VPMN dependencies and
                prove controlled environment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default AboutFront

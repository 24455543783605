import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import AboutFront from "../sections/about-front"
import HomeConsulting from "../sections/home-consulting"

const AboutUs = () => (
  <Layout>
    <SEO title="About Us" />
    {/* <!--================Common Banner Area =================--> */}
    <section className="banner_area banner_area_05">
      <div className="banner_inner d-flex align-items-center">
        <div
          className="overlay bg-parallax"
          data-stellar-ratio="0.9"
          data-stellar-vertical-offset="0"
          data-background=""
        ></div>
        <div className="container">
          <div className="banner_content text-center">
            <h2>About Us</h2>
            <div className="page_link">
              <Link to="/">Home</Link>
              <Link to="#">About Us</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--================End Common Banner Area =================--> */}
    <AboutFront />
    <HomeConsulting />
  </Layout>
)

export default AboutUs
